const classesToExclude = ['c-form-select__option', 'js-exclude-from-script-detection'];

const removeWhitespace = (str) => {
  const whitespaceChars = [' ', '\t', '\n', '!', '?', '.', ':', '-', '_'];
  let newString = str;

  whitespaceChars.forEach((char) => {
    newString = str.trim().split(char).join('');
  });

  return newString.trim();
};

const detectScript = (word) => {
  const regex = /^[’\sA-Za-z0-9]*$/u;

  return regex.test(removeWhitespace(word)) ? 'latin' : 'mandarin';
};

const processTextNode = (node) => {
  if (['', ' ', '&nbsp;'].includes(node.textContent.trim())) return;

  const symbols = node.textContent.split('');

  let currentScript = null;
  let currentSpan = null;
  const fragment = document.createDocumentFragment();

  symbols.forEach((symbol) => {
    if (![''].includes(symbol)) {
      const script = detectScript(symbol);

      if (script !== currentScript) {
        // Close the current span if one exists
        if (currentSpan) {
          fragment.appendChild(currentSpan);
        }

        // Create a new span for characters with a different script
        currentScript = script;
        currentSpan = document.createElement('span');

        // Apply the appropriate class based on the script
        currentSpan.classList.add(currentScript);
      }

      // Append the character to the current span
      currentSpan.textContent += symbol;
    } else {
      fragment.appendChild(document.createTextNode(' '));
    }
  });

  // Add the last span if it exists
  if (currentSpan) {
    fragment.appendChild(currentSpan);
  }

  // Replace the original text node with the modified fragment
  node.parentNode.replaceChild(fragment, node);
};

const splitTextByLanguage = (node) => {
  if (!node || classesToExclude.some((className) => node.classList?.contains(className))) return;

  if (node.hasChildNodes()) {
    const childrenArray = [...node.childNodes];

    childrenArray.forEach((child) => {
      splitTextByLanguage(child);
    });
  }

  if (node.nodeType === Node.TEXT_NODE) {
    processTextNode(node);
  }
};

// Start the traversal from the body element
if (document.documentElement.lang === 'zh') splitTextByLanguage(document.body);

export default splitTextByLanguage;
