import jQuery from 'jquery';

const arrowScroll = () => {
  const arrowContext = document.querySelectorAll('.c-header--light:not(.c-header--menu):not(.c-header--reset-cursor), .c-hero-homepage, .o-section--arrow-cursor');
  const cutoff = (jQuery('.c-hero-homepage').outerHeight()) - 150;

  const hasMask = document.querySelector('.c-hero-homepage__mask');

  if (hasMask) {
    window.addEventListener('scroll', () => {
      if (!document.querySelector('.c-header--light:not(.c-header--menu)')) {
        return;
      }

      if (jQuery('html').scrollTop() >= cutoff) {
        document.querySelector('.c-header--light:not(.c-header--menu)').classList.add('c-header--reset-cursor');
      } else {
        document.querySelector('.c-header--light:not(.c-header--menu)').classList.remove('c-header--reset-cursor');
      }
    });

    arrowContext.forEach((el) => {
      if (window.innerWidth >= 1024) {
        el.addEventListener('click', (e) => {
          let isHeader = false;
          let isException = false;

          if (e.target.classList.contains('c-header__content')) {
            isHeader = true;
          }

          if (
            (isHeader && e.target.closest('.c-header--light').classList.contains('c-header--reset-cursor'))
            || e.target.closest('.js_sound')
            || e.target.classList.contains('js-search-modal-toggle')) {
            isException = true;
          }

          if (!isException) jQuery('html, body').animate({ scrollTop: cutoff }, 300);
        });
      }
    });
  }
};

export default arrowScroll;
