const focusSearchModalInput = () => {
  const searchModalToggle = document.querySelector('.js-search-modal-toggle');
  const searchModalInput = document.querySelector('.js-search-modal-input');

  if (searchModalToggle && searchModalInput) {
    searchModalToggle.addEventListener('click', () => window.setTimeout(
      () => searchModalInput.focus(),
      0,
    ));
  }
};

export default focusSearchModalInput;
