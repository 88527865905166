export const removeDisabledOptions = () => {
  const customSelectContainers = document.querySelectorAll('.c-form-select__container');

  customSelectContainers.forEach((container) => {
    const defaultSelect = container.querySelector('select');
    const defaultSelectOptions = defaultSelect.querySelectorAll('option');
    const customSelect = container.querySelector('.c-form-select__panel');
    const customSelectOptions = customSelect.querySelectorAll('.c-form-select__option');
    const disabledOptionIdices = [];

    defaultSelectOptions.forEach((option, i) => {
      if (option.disabled) disabledOptionIdices.push(i);
    });

    customSelectOptions.forEach((option, i) => {
      if (disabledOptionIdices.includes(i)) option.remove();
    });
  });
};

export const addHandlingForRequired = () => {
  const fields = document.querySelectorAll('select, input, textarea');

  fields.forEach((field) => {
    if (field.required) {
      field.addEventListener('change', (e) => {
        e.target.parentNode.querySelectorAll('.c-modal-pardot__message--failure').forEach((el) => el.remove());
      });

      field.addEventListener('invalid', (e) => {
        e.preventDefault();

        if (!e.target.parentNode.querySelector('.c-modal-pardot__message--failure')) {
          const errorMessage = document.createElement('span');
          errorMessage.classList.add('c-modal-pardot__message--failure');
          errorMessage.innerText = 'This field is required';

          e.target.parentNode.append(errorMessage);
        }
      });
    }
  });
};
