import jQuery from 'jquery';

const subscribeFormToggler = () => {
  jQuery('.js-subscribe-toggler').on('click', function subscribeFormToggle() {
    const formContainer = jQuery('.js-form-replace');
    const actionUrl = formContainer.data('action-url');
    const placeholderFirstName = formContainer.data('placeholder-first-name');
    const placeholderLastName = formContainer.data('placeholder-last-name');
    const placeholderEmail = formContainer.data('placeholder-email');

    const formHtml = `
      <form
          class=""
          action="${actionUrl}"
          method="POST"
          data-gtm-name="Subscribe"
      >
          <div class="c-subscribe__fieldset">
              <div class="c-subscribe__field">
                  <input 
                    type="text" 
                    name="firstName" 
                    class="js-cm-first-name-input" 
                    id="fieldFirstName" 
                    placeholder="${placeholderFirstName}" 
                    autofocus required=""
                  >
              </div>

              <div class="c-subscribe__field">
                  <input 
                    type="text" 
                    name="lastName" 
                    class="js-cm-last-name-input" 
                    id="fieldLastName" 
                    placeholder="${placeholderLastName}"
                    autofocus 
                    required=""
                  >
              </div>

              <div class="c-subscribe__field">
                  <input 
                    type="email" 
                    name="email" 
                    class="js-cm-email-input" 
                    id="fieldEmail" 
                    autofocus 
                    placeholder="${placeholderEmail}" 
                    required=""
                  >
              </div>

              <div class="c-subscribe__button__mobile">
                  <button type="submit">Submit</button>
              </div>

              <div class="c-subscribe__button">
                  <button type="submit" class="svg-arrow-right">
                      <svg>
                          <use xlink:href="#arrow-right"></use>
                      </svg>
                  </button>
              </div>
          </div>
      </form>
    `;

    formContainer.replaceWith(formHtml);

    jQuery(this).parent().parent().find('.c-subscribe__fieldset')
      .toggleClass('active');
    jQuery(this).parent().parent().find('.js-cm-first-name-input')
      .focus();
  });
};

export default subscribeFormToggler;
