import jQuery from 'jquery';

const gdpr = () => {
  const box = jQuery('.js-gdpr');
  jQuery('.js-gdpr-cta').on('click', () => {
    document.cookie = 'gdpr=accepted;';
    box.removeClass('open');
  });
  if (document.cookie.indexOf('gdpr=accepted') < 0) {
    box.addClass('open');
  }
};

export default gdpr;
