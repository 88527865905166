import jQuery from 'jquery';

const menuToggler = () => {
  jQuery('.js-menu-toggler').on('click', function soundIconToggle() {
    jQuery(this).toggleClass('menu-toggler--close');
    jQuery('body').toggleClass('menu-open');
    jQuery('.js-header').toggleClass('c-header--menu');
  });
};

export default menuToggler;
