import jQuery from 'jquery';

const stickyHeader = () => {
  const header = jQuery('.c-header');
  const logo = jQuery('.c-header__logo');
  const heroHeight = jQuery('.js-hero').innerHeight();
  const headerHeight = jQuery('.js-header').innerHeight();

  function checkHeader() {
    const pos = header.position();
    const windowpos = jQuery(window).scrollTop();
    if (windowpos >= pos.top && windowpos <= heroHeight - headerHeight) {
      header.removeClass('out-of-hero');
    } else {
      header.addClass('out-of-hero');
    }

    if (windowpos > pos.top) {
      header.removeClass('at-the-top');
      logo.removeClass('svg-Hassell-logo-text').addClass('svg-Hassell-logo-icon');
      jQuery('svg use', logo).attr('xlink:href', '#Hassell-logo-icon');
    } else {
      header.addClass('at-the-top');
      logo.addClass('svg-Hassell-logo-text').removeClass('svg-Hassell-logo-icon');
      jQuery('svg use', logo).attr('xlink:href', '#Hassell-logo-text');
    }
  }
  checkHeader();
  jQuery(window).scroll(() => {
    checkHeader();
  });
};

export default stickyHeader;
